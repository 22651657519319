import getData from "@/__main__/get-data.mjs";
import fetchWikiData from "@/game-eft/fetches/fetch-wiki-data.mjs";
import fleaMarketModel from "@/game-eft/models/flea-market.mjs";
import * as API from "@/game-eft/utils/api.mjs";
import { getCategoriesBySlug } from "@/game-eft/utils/flea-market.mjs";
import { NotFoundError } from "@/util/custom-errors.mjs";

async function fetchData(params: string[]) {
  const [categoryName] = params;
  const requiredPromises = [];

  const fleaFetch = getData(
    API.getFleaMarketData(),
    fleaMarketModel,
    ["eft", "fleaMarket"],
    {
      shouldFetchIfPathExists: true,
      mergeFn(current, next) {
        return next ? next : current;
      },
    },
  );

  await fetchWikiData();

  if (categoryName && !getCategoriesBySlug(categoryName))
    throw new NotFoundError(`Category ${categoryName} not found`);

  requiredPromises.push(fleaFetch);

  return Promise.all(requiredPromises);
}

export default fetchData;
